html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, avenir, helvetica, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@import url('./tachyons.css');
@import url('./globals.css');
