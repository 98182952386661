body {
  background: #f6f8fb;
  font-family: -apple-system, avenir, sans-serif !important;
}
/* inputs */
.dgt-only-bb {
  border: none;
  border-bottom: solid 1px;
}

.dgt-input-transition {
  transition: 0.2s all;
  cursor: text;
}

input.dgt-input:invalid {
  outline: 0;
  border-color: #f44771;
  color: #f44771;
}

input.dgt-input:invalid ~ label {
  top: -24px;
  font-size: 10px;
  color: #f44771;
}

input.dgt-input:focus {
  color: #3e8af8;
}
input.dgt-input:focus ~ label {
  color: #3e8af8;
  font-size: 10px;
  top: -24px;
}

.dgt-f12 {
  font-size: 0.75rem;
}

.dgt-tooltip-bg {
  background-color: #e8edf4;
}

.dgt-tooltip-color {
  color: #6e7b91;
}
